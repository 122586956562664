import React from 'react';
import { Row, Col, Image} from 'react-bootstrap';
import '../styles/gallery.scss';

export default function  GalleryLight({listOfImages, onLoad}){
    return (
        <div>
        {
            listOfImages.map(
              (image, index) =>  
              <Row className = "text-center">
                {image[0] &&
                  <Col md={{ span: 4 }}>
                    <Image key={index * 3} src={image[0]} alt="info" className='galleryLightImage mb-4' onLoad={onLoad}/>
                  </Col>
                }
                {image[1] &&
                  <Col md={{ span: 4 }}>
                    <Image key={index * 3 + 1} src={image[1]} alt="info" className='galleryLightImage mb-4' onLoad={onLoad}/>
                  </Col>
                }
                {image[2] &&
                  <Col md={{ span: 4 }}>
                    <Image key={index * 3 + 2} src={image[2]} alt="info" className='galleryLightImage mb-4' onLoad={onLoad}/>
                  </Col>
                }
              </Row>
            )
        }
        </div>
    )
}