
import React from 'react';
import { Container} from 'react-bootstrap';
import { Player } from 'video-react';
import Video from '../Videos/hand.mkv';
import '../styles/video.scss'

export default class Animation extends React.Component{     
  render(){
    return (
      <Container>
        <h1 className='text-center m-5'>Animation</h1>
        <Player
          poster="/assets/poster.png"
          src={Video}
          className="galleryVideo mb-5"
          autoPlay
        />
      </Container>
    );
  }
}