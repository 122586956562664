import React from 'react';
import { Container, Image, Row, Col} from 'react-bootstrap';
import First from '../Images/about/iulia.jpg';
import Instagram from '../Images/about/instagram.png';
import Fiver from '../Images/about/fiver.png';

import '../styles/about.scss'

export default function About() {
    return (
        <Container>
        <Row className='text-center mt-5 mb-2 justify-content-md-center'>
            <Col md={{ span: 6 }} >
                <Image  src={First} className=" imageBorder painting mt-3 mb-4" alt="First"/>
                <h5>
                Romanian artist, currently undergoing a bachelor’s in Fine Art at Willem de Kooning Academy,
                Rotterdam. My base mediums are drawing and photography, actively exploring different
                mediums, such as digital art animation. I explore topics in an organic and flowy manner,
                focusing on my inner struggles and overcomings, but also letting myself indulge in anything that
                catches my eye and spirit.
                </h5>
                <h3 className='mt-3'>Contact:</h3>
                <h4>iulia.andreea.petcu@gmail.com</h4>
            </Col>
        </Row>
        <Row className='text-center justify-content-md-center mb-5'>
            <Col >
                <a href="https://www.instagram.com/yur_arta/?hl=en">
                <Image  src={Instagram} className="instagram me-4" alt="Instagram"/>
                </a>
                <a href="www.fiverr.com/s2/9a10bdb44b">
                    <Image  src={Fiver} className="fiver" alt="Fiver"/>
                </a>
            </Col>
        </Row>
      </Container>
    );
}