
import React from 'react';
import { Container, Image, Row, Col} from 'react-bootstrap';
import { Link } from "react-router-dom";
import Digital from '../Images/photography/digital.jpg';
import Analog from '../Images/photography/analog.jpg';
import '../styles/photography.scss'

export default class Photography extends React.Component{

  render(){
    return (
      <Container>
        <h1 className='text-center m-5'>Photography. Analog and Digital.</h1>
        <Row >
            <Col md={{ span: 6 }}  className = "text-center mb-5 mb-md-0">
                <Link to="/digital">
                    <div className="text-center mainImage">
                    <Image  src={Digital} className="mainImage4 imageBorder painting" alt="Digital"/>
                        <div className="centered"><h1>Digital</h1></div>
                    </div>
                </Link>
            </Col>
            <Col md={{ span: 6 }} className = "text-center">
                <Link to="/analog">
                    <div className="text-center mainImage">
                        <Image  src={Analog} className="mainImage4 imageBorder painting" alt="Analog"/>
                        <div className="centered"><h1>Analog</h1></div>
                    </div>
                </Link>
            </Col>
        </Row>
      </Container>
    );
  }
}