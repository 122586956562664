
import React from 'react';
import { Container} from 'react-bootstrap';
import GalleryLight from '../components/galleryLight';


export default class Digital extends React.Component{

  constructor(props) {
    super(props);
    this.state = {listOfImages: []};
  }

  importAll(r) {
    return r.keys().map(r);
  }

  componentDidMount() {
    const listOfImages = this.importAll(require.context('../Images/photography/Digital', false, /\.(png|jpe?g|JPG|svg)$/));
    const rows = listOfImages.reduce(function (rows, key, index) { 
      return (index % 3 === 0 ? rows.push([key]) 
        : rows[rows.length-1].push(key)) && rows;
    }, []);
    this.setState({listOfImages:rows});
  }

  render(){
    return (
      <Container>
        <h1 className='text-center m-5'>Digital</h1>
        <GalleryLight listOfImages={this.state.listOfImages} />
      </Container>
    );
  }
}