import React from 'react';
import ReactDOM from 'react-dom';
import App from './pages/start';
import Animation from './pages/animation';
import Digital from './pages/digital';
import Analog from './pages/analog';
import Painting from './pages/painting';
import Perception from './pages/perception';
import Photography from './pages/photography';
import Light from './pages/light';
import Sanzi from './pages/sanzi';

import About from './pages/about';
import reportWebVitals from './reportWebVitals';


import 'bootstrap/dist/css/bootstrap.min.css';
import 'video-react/dist/video-react.css';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import './styles/index.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/perception" element={<Perception/>}/>
        <Route path="/animation" element={<Animation/>}/>
        <Route path="/light" element={<Light/>}/>
        <Route path="/painting" element={<Painting/>}/>
        <Route path="/photography" element={<Photography/>}/>
        <Route path="/digital" element={<Digital/>}/>
        <Route path="/analog" element={<Analog/>}/>
        <Route path="/sanzi" element={<Sanzi/>}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
