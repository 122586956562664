
import React from 'react';
import { Container, Image, Row, Col} from 'react-bootstrap';
import PaintingImage from '../Images/3.jpg';
import '../styles/painting.scss'

export default class Painting extends React.Component{

  render(){
    return (
      <Container>
        <h1 className='text-center m-5'>Painting</h1>
        <Row >
            <Col className = "text-center">
                <Image  src={PaintingImage} className="painting mb-5" alt="Image2"/>
            </Col>
        </Row>
      </Container>
    );
  }
}