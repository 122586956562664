
import React from 'react';
import { Container, Image, Row, Col} from 'react-bootstrap';
import First from '../Images/sanzi/1.jpg';
import Second from '../Images/sanzi/2.png';
import '../styles/photography.scss'
import '../styles/sanzi.scss'

export default class Photography extends React.Component{

  render(){
    return (
      <Container>
        <h1 className='text-center mt-5'>Sânzi</h1>
        <div>
            “Unii spun că în noaptea aceasta, exact la miezul nopţii, se deschid cerurile. (…) Dar
            probabil că se deschid numai pentru cei care ştiu cum să le privească.”
            ― Mircea Eliade,  Noaptea de Sânziene
            (&quot;Some people say that tonight, at exactly midnight, the skies open. (…) But they are probably
            only open to those who know how to look at them. ”
            - Mircea Eliade, Sânziene Night)<br/> <br/>

            In Romanian folklore is believed that every year, on the 24 th of June, at midnight, the skies open
            and the borders between the physical and the spiritual world fade away. That is the time when
            the “sânziene”, the good fairies, reveal themselves and bring prosperity and good fortune for the
            upcoming winter. A night when wishes have stronger power and fate can be altered. It is also
            the perfect moment to reap the Galium verum flower, which is the fairy’s transposition in the
            local flora.<br/> <br/>

            “Sânzi” depicts a sânziana yearning for the sky. This light installation was originally designed as
            a piece for the Winterlicht Festival, which, due to covid-19 restrictions, got canceled. The
            installation was exhibited in Sint Jo Church, in Schiedam.
        </div>
        <Row className='text-center mt-5 mb-5'>
            <Col md={{ span: 6 }}  className = "text-center mb-5 mb-md-0">
                <Image  src={First} className=" imageBorder painting" alt="First"/>
            </Col>
            <Col md={{ span: 6 }} className = "text-center">
                <Image  src={Second} className=" imageBorder painting second" alt="Second"/>
            </Col>
        </Row>
      </Container>
    );
  }
}