
import React, {useEffect, useState} from 'react';
import { Container} from 'react-bootstrap';
import GalleryLight from '../components/galleryLight';

export default function Analog(){

    const [listOfImages, setListOfImages] = useState([]);
    /*
    const [loading, setLoading] = useState(true);
    const counter = useRef(0);
    const imageLoaded = () => {
    counter.current += 1;
      if (counter.current >= 10) {
        setLoading(false);
      }
    }*/

    const importAll = (r) => {
        return r.keys().map(r);
    };

    useEffect(() => {
        const listOfImages = importAll(require.context('../Images/photography/Analog', false, /\.(png|jpe?g|JPG|svg)$/));
        const rows = listOfImages.reduce(function (rows, key, index) { 
            return (index % 3 === 0 ? rows.push([key]) 
            : rows[rows.length-1].push(key)) && rows;
        }, []);
        setListOfImages(rows);
    },[]);
    
    return (
        <Container>
            <div>
                <h1 className='text-center m-5'>Analog</h1>
                <GalleryLight listOfImages={listOfImages} /*onLoad={imageLoaded}*/ />
            </div>
        </Container>

    )
}