import { Link } from "react-router-dom";
import { Container, Row, Col, Image} from 'react-bootstrap';
import Image1 from "../Images/1.jpg"
import Image2 from "../Images/2.jpg"
import Image3 from "../Images/3.jpg"
import Image4 from "../Images/4.jpg"
import Image5 from "../Images/5.png"
import Image6 from "../Images/6.png"
import Name from "../Images/nume.png"


import '../styles/App.scss';


function App() {
  return (
    <Container >

      <Row className = "mt-4 text-start m-1" >
        <Link className="about" to="/about"><h2>About</h2></Link>
      </Row>
      
      <div class="text-center mt-4 mt-md-2">
        <img  src={Name} className="name mx-auto" alt="name"/>
      </div>

      <Row className="mb-0 mb-md-5 gx-md-4 justify-content-md-center" >
        <Col  md={{ span: 4 }} className= "mb-5 mb-md-0"> 
          <Link to="/perception">
            <div className="text-center mainImage">              
              <Image  src={Image5} className="mainImage1 imageBorder img-responsive" alt="Image1"/>
              <div className="centered"><h3>Outlines of perception</h3></div>
            </div>
          </Link>
        </Col>
        <Col  md={{ span: 4 }} className= "mb-5 mb-md-0" >
          <Link to="/animation">
            <div className="text-center mainImage">
              <Image  src={Image6} className="mainImage2 imageBorder" alt="Image2"/>
              <div className="centered2 "><h2>Animation</h2></div>
            </div>
          </Link>
        </Col>
        <Col  md={{ span: 4 }} className= "mb-5 mb-md-0 text-center ps-md-5">
          <Link to="/painting">
            <div className="text-center mainImage">
              <Image  src={Image3} className="mainImage3 imageBorder" alt="Image1"/>
              <div className="centered"><h2>Painting</h2></div>
            </div>
          </Link>
        </Col>
      </Row>  
      <Row className="gx-md-5 mb-md-5">
        <Col  md={{ span: 4}} className= "mb-5 mb-md-0">
          <Link to="/light">
          <div className="text-center mainImage">
            <Image  src={Image2} className="mainImage4 imageBorder" alt="Image2"/>
            <div className="centered"><h2>Flows of Light</h2></div>
          </div>
          </Link>
        </Col>  
        <Col  md={{ span: 4 }} className= "mb-5 mb-md-0">
          <Link to="/photography">
            <div className="text-center mainImage">
              <Image  src={Image1} className="mainImage4 imageBorder" alt="Image1"/>
              <div className="centered"><h2>Photography</h2></div>
            </div>
          </Link>
        </Col>
        <Col  md={{ span: 4 }} className= "mb-5 mb-md-0" >
          <Link to="/sanzi">
            <div className=" mainImage ">
              <Image  src={Image4} className="mainImage5 imageBorder" alt="Image2"/>
              <div className="centered text-center"><h2>Sânzi</h2></div>
            </div>
          </Link>
        </Col>
      </Row>

    </Container>
  );
}

export default App;
